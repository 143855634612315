.tag8-nav-img {
  width: 6rem;
  height: 6rem;
}
.tag8-color,
.tag8-text {
  color: #fdbe33;
}

.row-main-wrapper {
  padding-bottom: 10rem;
}

.MuiDivider-wrapper {
  font-size: 1rem !important;
  font-weight: bold !important;
}

.cylinder-img-div {
  width: 15rem;
  height: 15rem;
  border: 1px solid lightgray;
  border-radius: 50%;
  padding: 1rem;
  margin: 0.5rem;
}
.cylinder-img {
  width: 100%;
  object-fit: cover;
}

.mui-accordation {
  width: 100%;
}

.two-field-wrapper {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
}

.mui-text-field {
  width: 50%;
}
/* .mui-btn {
  width: 25%;
} */
.mui-text-field-single {
  width: 49% !important;
}
.hydrotest-period-no,
.hydrotest-period-text {
  width: 50% !important;
}
.hydrotest-period-no fieldset {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.hydrotest-period-text fieldset {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.MuiTextField-root {
  width: 100%;
}

.css-qfz70r-MuiFormGroup-root,
.css-qfz70r-MuiFormGroup-root span {
  flex-wrap: nowrap !important;
  font-size: 12px !important;
}

.test-add-btn {
  background-color: #fcbc1b !important;
  width: 100% !important;
}
.form-submit-btn {
  background-color: #fcbc1b !important;
  width: 50% !important;
}
.edit-btn {
  background-color: #fcbc1b !important;
}
.logout-btn {
  background-color: white !important;
  /* width: 50% !important; */
}
.form-cancel-btn {
  background-color: whitesmoke !important;
  width: 50% !important;
  color: black !important;
}

.transaction-info {
  font-size: 13px;
  text-align: start;
}
.text-content-lebal {
  font-weight: 900;
  font-size: 10px;
}
.text-content {
  font-weight: 600;
  font-size: 11px;
}

.empty-full-img {
  width: 20px;
  height: 19px;
}

.test-info {
  font-weight: 600;
}

.testedby-text {
  font-style: italic;
}
.contact-info-div {
  background: #fdbe33;
  width: 98%;
  position: fixed;
  bottom: 0%;
  left: 0%;
}

.arrow-up-down-icon {
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.arrow-up {
  bottom: -10% !important;
  transition: 0.5s;
}
.arrow-down {
  bottom: -1% !important;
  transition: 0.5s;
}

.anchor-tag {
  text-decoration: none;
  color: black;
}

.email-img-vector {
  width: 2rem;
  height: 2rem;
  margin: 0 0.5rem;
}

.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
}

/* Test Comment Badge   */
.css-u6pgqw-MuiBadge-badge {
  top: 2.7rem !important;
  right: 1.1rem !important;
}

/*  MUI STACK : Transaction and maintenance*/
/* .css-sj94p2-MuiStack-root {
  height: 17.1rem !important;
  overflow: scroll !important;
} */

/* MEDIA QUERY */
@media screen and (max-width: 500px) {
  .mui-text-field {
    width: 100%;
  }
  .mui-text-field-single {
    width: 100% !important;
  }
  .two-field-wrapper {
    flex-direction: column;
  }
  .test-log-lebal {
    font-size: 12px;
  }
}
@media screen and (max-width: 405px) {
  .text-content-lebal {
    font-size: 9px;
  }
  .text-content {
    font-size: 8px;
  }
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-error
  .MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0.23) !important ;
}
.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-error {
  color: rgba(0, 0, 0, 0.6) !important;
}
