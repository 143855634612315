.main-container {
  position: relative;
}
.csv-file-div {
  position: absolute;
  top: 1rem;
  right: 7rem;
}

.csv-upload-btn {
  border: 0.25px solid grey;
  border-radius: 5px;
  font-weight: 600;
  padding: 6px;
  color: black;
  cursor: pointer;
  background-color: white;
  text-decoration: none;
  font-size: 12px;
  font-weight: 500;
  margin-right: 5px;
}
.csv-upload-btn:hover {
  transition: 0.5s;
  background-color: whitesmoke;
  border: 1px solid #fcbc1b;
}

.input-csv-tag-none {
  position: absolute;
  display: none;
  top: 13em;
  left: -1.5rem;
}

.delete-btn {
  border: 1px solid red;
  border-radius: 50%;
}

.check-btn {
  border: 1px solid green;
  border-radius: 50%;
}
.declined-text {
  border: 2px solid red;
  border-radius: 30%;
  padding: 5px;
  font-weight: 700;
  background: #ff8787;
}

.accepted-text {
  border: 2px solid green;
  border-radius: 30%;
  padding: 5px;
  font-weight: 700;
  background: #b7ffb7;
}

.search-input {
  padding: 6px;
  border: 0.25px solid grey;
  outline: none;
  font-size: 12px;
  border-radius: 5px;
  width: 12.2rem;
}
.viewlist-icon {
  font-size: 3rem !important;
  cursor: pointer;
}

.view-list-div {
  position: relative;
}
.check-box {
  background: white;
  position: absolute;
  width: 20rem;
  height: 35rem;
  z-index: 99;
  border: 1px solid black;
  border-radius: 0.5rem 0 0 0.5rem;
  right: 0rem;
  overflow: scroll;
  padding: 1rem;
  overflow-x: hidden;
  scroll-behavior: smooth;
}
.check-box-hide {
  display: none;
}
.bg-green {
  color: #febd1f;
  font-weight: 700;
}
