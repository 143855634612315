.blulk-scan-btn-div {
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
}
.blulk-scan-history-div {
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
}
.link-btn-span {
  font-size: 13px;
}

.bulk-scan-type-icon {
  width: 2rem;
  height: 2rem;
}

.cylinder-type-img-div {
  width: 6rem;
  height: 6rem;
  border: 1px solid lightgray;
  border-radius: 50%;
  padding: 1rem;
  margin: 0.5rem;
}
.cylinder-type-img {
  width: 100%;
  object-fit: cover;
}

.scanner-main-div {
  position: relative;
}
.scanner-div {
  width: 100%;
}
.scanned-message {
  position: absolute;
  bottom: 2%;
  left: 25%;
}

.scan-tag-number {
  padding: 3px;
  /* border: 0.25px solid gray; */
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 0.25px solid gray;
  font-size: 14px;
}
.bg-tag {
  background: #fcbc1b;
  border: 2px solid red;
}

.scan-tag-div {
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 10rem;
  border: 0.25px solid gray;
}
.h-17 {
  height: 17rem;
}

.scan-tags-wrapped {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-right: 0.25px solid gray;
}

.cursor-pointer {
  cursor: pointer;
}
.note-heading {
  color: red;
  font-size: 14px;
  font-weight: 700;
}
.note-message {
  font-size: 12px;
  font-weight: 600;
}

/* For Webkit browsers (Chrome, Safari) */
.scroll-container::-webkit-scrollbar {
  width: 10px;
}

.scroll-container::-webkit-scrollbar-thumb {
  background-color: #fcbc1b;
}

.scroll-container::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

/* Optional: Hover styles */
.scroll-container::-webkit-scrollbar-thumb:hover {
  background-color: #fcbc1b;
}

.scroll-container::-webkit-scrollbar-thumb:active {
  background-color: #fcbc1b;
}

.cylinder-management-row-div {
  border: 0.25px solid gray;
  padding: 6px !important;
}

.dfjcac {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dfcc {
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 5rem; */
  border: 0.25px solid gray;
  border-radius: 7px;
  background: white;
  text-decoration: none;
  color: black;
  height: 10rem;
}

.fa-20px {
  font-size: 20px;
}

.cylinder-management-screen {
  background: whitesmoke;
  height: 100vh;
}
.remove-bg {
  background: none !important;
}

/* tag-clr -> #fcbc1b */

.tab-btn0 {
  color: black !important;
  /* z-index: 999 !important; */
  border: 0.25px solid !important;
  border-radius: 0 16px 0 0 !important;
}
.tab-btn1 {
  color: black !important;
  /* z-index: 999 !important; */
  border: 0.25px solid !important;
  border-radius: 16px 0 0 0 !important;
}
.css-1aquho2-MuiTabs-indicator {
  background: none !important;
  height: 0px !important;
}

.bg-tag8-clr {
  background: #fcbc1b !important;
}
.transaction-form-lebal {
  text-align: center !important;
  text-decoration: underline !important;
  margin-bottom: 1rem !important;
}

.transaction-form-radio-grp {
  gap: 1rem !important;
}

.transaction-main-div {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.bt-gray {
  border: 0.25px solid gray;
}

.bb-1 {
  border: 0;
  border-bottom: 0.25px solid gray !important;
}
.bb-0 {
  border-bottom: 0 !important;
}

.qrcode-testdate-h6 {
  width: 60%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.qrcode-testdate-span {
  width: 56%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.recent-maintenance-main-div {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.recent-maintenance-div {
  width: 100%;
  display: flex;
}
.recent-scanTag-div {
  width: 40%;
}
.recent-tareWeight-div {
  width: 60%;
}
.recent-addBtn-div {
  width: 40%;
}

table,
td,
th {
  border: 0.25px solid gray;
}

table {
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  text-align: center;
}

.table-div {
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 10.9rem;
  border: 0.25px solid gray;
}

.single-transaction {
  margin-top: 0.5rem;
  padding: 0 !important;
}
.bulk-transaction {
  margin-top: 0.5rem;
  background: #9ff79f70 !important;
}

.item-card-single {
  background: #e1e1e1d4 !important;
}
.item-card-bulk {
  background: #9ff79f00 !important;
}
.box-green {
  width: 13px;
  height: 13px;
  background: green;
}
.box-gray {
  width: 13px;
  height: 13px;
  background: gray;
}
