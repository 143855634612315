.main-container {
  position: relative;
}
.nav-btn-div {
  position: absolute;
  top: 1rem;
  right: 7rem;
}

.nav-btn {
  border: 0.25px solid grey;
  border-radius: 5px;
  padding: 6px;
  color: black;
  cursor: pointer;
  background-color: white;
  text-decoration: none;
  font-size: 12px;
}
.nav-btn:hover {
  transition: 0.5s;
  background-color: whitesmoke;
  border: 1px solid #fcbc1b;
}

.text-bold {
  font-weight: 700;
}
