.h-90 {
  height: 90vh;
}
.w-100p {
  width: 100%;
}
.gap-6 {
  gap: 6rem;
}

.login-field {
  border-width: 0 !important;
}
/* mui modal content */
.css-gtvet1-MuiDialogContent-root {
  padding: 0 !important;
}

.div-border {
  border: 0.25px solid gray;
  border-radius: 5px;
  /* background: whitesmoke; */
}
.icon-text {
  border-left: 0.25px solid gray;
  width: 80%;
  /* background: white; */
}
.icon-div {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo-div {
  display: block;
  width: 10rem;
}

.logo-img {
  width: 10rem;
}

/* mui password text field */
.css-r47a1p-MuiFormControl-root {
  width: 100% !important;
  margin: 0 !important;
}
.login-form {
  width: 100%;
}
.login-sumit-btn {
  background-color: #fcbc1b !important;
  width: 100% !important;
}
.forgot-pass-btn {
  background-color: #fcbc1b !important;
}
.forgot-password-btn {
  border: none;
  background: transparent;
  border-bottom: 2px solid #b0b0b0;
  /* position: absolute;
    top: -1rem;
    right: 11.75rem; */
}
.resend-otp-btn {
  border: none;
  background: transparent;
  border-bottom: 2px solid #fcbc1b;
}

.password-div {
  width: 100%;
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
}
.forgot-btn-div {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

/* 

class="MuiOutlinedInput-notchedOutline css-1d3z3hw-MuiOutlinedInput-notchedOutline"
class="MuiOutlinedInput-notchedOutline css-1d3z3hw-MuiOutlinedInput-notchedOutline"

*/
