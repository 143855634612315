/* .css-4t3x6l-MuiPaper-root-MuiDrawer-paper {
  width: 20% !important;
  padding: 1rem !important;
  font-size: 20px !important;
  gap: 1rem !important;
} */
.logo-div {
  cursor: pointer;
}

.nav-logo-img {
  width: 5.625rem;
}
.drawer-div {
  width: 15rem !important;
}
.drawer-btn {
  cursor: pointer;
  margin: 0.5rem 1rem 0 0 !important;
}
.drawer-ul {
  margin-left: 1rem;
}

.close-btn-div {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}
.close-btn {
  font-size: 2.5rem !important;
}

.pos-relative {
  position: relative;
}
.pos-absolute {
  display: none;
  position: absolute;
  right: 1rem;
  top: 39px;
  z-index: 99;
  background: white;
  border: 0.25px solid rgb(255, 200, 0);
  border-radius: 20px 0rem 20px 20px;
}

.user-info {
  padding: 0.7rem;
  text-wrap: nowrap;
  border-bottom: 1px solid rgb(255, 200, 0);
  margin-bottom: 0;
  text-align: center;
}

.logout-btn {
  font-weight: 700;
}
.logout-btn:hover {
  cursor: pointer;
}

.d-hide {
  display: none;
}
.d-show {
  display: block;
}

.avatar:hover {
  cursor: pointer;
}

.logout-btn-div {
  cursor: pointer;
  border-top: 0.25px solid rgb(255, 200, 0);
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logout-btn-div:hover {
  transition: 0.4s;
  background-color: rgb(255, 200, 0);
  color: white;
  border-radius: 0rem 0rem 1rem 1rem;
}

.activity-text {
  font-size: 14px;
  text-wrap: nowrap;
}
