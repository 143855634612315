.width-100 {
  width: 100% !important;
}
.cursor-pointer {
  cursor: pointer;
}
.fab-btn {
  width: 2.5rem !important;
  height: 2.5rem !important;
  z-index: 0 !important;
  box-shadow: none !important;
  margin-right: -12px !important;
}
.p-tag-size {
  font-size: 15px;
  cursor: pointer;
}
.popover-mui {
  width: 20rem !important;
}
.cancel-btn {
  background-color: #d3d3d3 !important;
  color: black !important;
  width: 40% !important;
}
.add-btn {
  background-color: #fcbc1b !important;
  width: 40% !important;
}

.master-info {
  display: inline-block;
  cursor: pointer;
  position: relative;
}

.edit-icon,
.delete-icon {
  display: none !important;
}

.master-info:hover .edit-icon {
  transition: 1s;
  display: inline-block !important;
  cursor: pointer;
  position: absolute;
  right: 1.5rem;
}

.master-info:hover .delete-icon {
  transition: 1s;
  display: inline-block !important;
  cursor: pointer;
  position: absolute;
  right: 0rem;
}
